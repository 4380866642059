import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import en from '../locales/en/translations.json';
import de from '../locales/de/translations.json';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0
    },
    fallbackLng: 'de',
    supportedLngs: ["de"],
    ns: ["translations"],
    defaultNS: "translations",
    debug: process.env.NODE_ENV === 'production' ? false : true,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    },
    resources: {
      de: {
        translations: de
      },
      en: {
        translations: en
      }
    }
  })
export default i18n